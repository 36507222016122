<section class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="box box-danger">
        <div class="box-header with-border">
          <div class="row">
            <div class="col-lg-3 col-xs-6">
              <!-- small box -->
              <div class="small-box bg-aqua">

                <div class="icon">
                  <i class="fa fa-user"></i>
                </div>
                <div class="inner">
                  <h3>00</h3>
                  <p>Active Doctors</p>
                </div>

                <a href="#" class="small-box-footer">
                  More info <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-xs-6">
              <!-- small box -->
              <div class="small-box bg-green">
                <div class="inner">
                  <h3>00</h3>
                  <p>Active Clinics</p>
                </div>
                <div class="icon">
                  <i class="fa fa-plus-square"></i>
                </div>
                <a href="#" class="small-box-footer">
                  More info <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-xs-6">
              <!-- small box -->
              <div class="small-box bg-yellow">
                <div class="inner">
                  <h3>00</h3>
                  <p>Total Cases</p>
                </div>
                <div class="icon">
                  <i class="ion ion-person-add"></i>
                </div>
                <a href="#" class="small-box-footer">
                  More info <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-xs-6">
              <!-- small box -->
              <div class="small-box bg-red">
                <div class="inner">
                  <h3>00</h3>
                  <p>New Cases</p>
                </div>
                <div class="icon">
                  <i class="ion ion-pie-graph"></i>
                </div>
                <a href="#" class="small-box-footer">
                  More info <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <!-- ./col -->
          </div>
        </div>
      </div>
      <!--chart-->
      <div class="row">
        <div class="col-md-6">
          <!-- AREA CHART -->
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Patients  </h3>

              <div class="box-tools pull-right">
                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
              </div>
            </div>
            <div class="box-body chart-responsive">
              <div class="chart" id="revenue-chart"
                style="height: 300px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);"><svg height="300" version="1.1"
                  width="595.8" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="overflow: hidden; position: relative; top: -0.6px;">
                  <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.3.0</desc>
                  <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs><text x="49.21875" y="261.375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">0</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,261.375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="202.28125"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">7,500</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,202.28125H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="143.1875"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">15,000</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,143.1875H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="84.09375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">22,500</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,84.09375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="25"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">30,000</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,25H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="477.39627126366946"
                    y="273.875" text-anchor="middle" font-family="sans-serif" font-size="12px" stroke="none"
                    fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2021</tspan>
                  </text><text x="251.00047843256377" y="273.875" text-anchor="middle" font-family="sans-serif"
                    font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2020</tspan>
                  </text>
                  <path fill="#74a5c2" stroke="none"
                    d="M61.71875,219.36328333333333C75.94580801944107,219.87542916666666,104.3999240583232,222.9374703125,118.62698207776427,221.41186666666667C132.85404009720534,219.88626302083333,161.30815613608746,209.42817083333333,175.53521415552854,207.15845416666667C189.6076302399757,204.91340833333334,217.75246240886997,205.1679796875,231.82487849331713,203.35281666666668C245.89729457776428,201.53765364583336,274.0421267466586,195.18290659722226,288.1145428311057,192.63715000000002C302.3416008505468,190.0634180555556,330.7957168894289,182.76750885416666,345.02277490886996,182.8748625C359.24983292831104,182.98221614583335,387.7039489671932,204.4652861111111,401.93100698663426,193.49597916666667C416.0034230710814,182.64590381944444,444.1482552399757,102.06621622928179,458.2206713244228,95.59733333333335C472.138445473876,89.19953706261512,499.9739937727825,135.31303052884618,513.8917679222357,142.02926250000002C528.1188259416767,148.89474407051284,556.5729419805589,147.95045625000003,570.8,149.92418750000002L570.8,261.375L61.71875,261.375Z"
                    fill-opacity="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></path>
                  <path fill="none" stroke="#3c8dbc"
                    d="M61.71875,219.36328333333333C75.94580801944107,219.87542916666666,104.3999240583232,222.9374703125,118.62698207776427,221.41186666666667C132.85404009720534,219.88626302083333,161.30815613608746,209.42817083333333,175.53521415552854,207.15845416666667C189.6076302399757,204.91340833333334,217.75246240886997,205.1679796875,231.82487849331713,203.35281666666668C245.89729457776428,201.53765364583336,274.0421267466586,195.18290659722226,288.1145428311057,192.63715000000002C302.3416008505468,190.0634180555556,330.7957168894289,182.76750885416666,345.02277490886996,182.8748625C359.24983292831104,182.98221614583335,387.7039489671932,204.4652861111111,401.93100698663426,193.49597916666667C416.0034230710814,182.64590381944444,444.1482552399757,102.06621622928179,458.2206713244228,95.59733333333335C472.138445473876,89.19953706261512,499.9739937727825,135.31303052884618,513.8917679222357,142.02926250000002C528.1188259416767,148.89474407051284,556.5729419805589,147.95045625000003,570.8,149.92418750000002"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                  <circle cx="61.71875" cy="219.36328333333333" r="4.36" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="118.62698207776427" cy="221.41186666666667" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="175.53521415552854" cy="207.15845416666667" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="231.82487849331713" cy="203.35281666666668" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="288.1145428311057" cy="192.63715000000002" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="345.02277490886996" cy="182.8748625" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="401.93100698663426" cy="193.49597916666667" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="458.2206713244228" cy="95.59733333333335" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="513.8917679222357" cy="142.02926250000002" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="570.8" cy="149.92418750000002" r="4" fill="#3c8dbc" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <path fill="#eaf3f6" stroke="none"
                    d="M61.71875,240.36914166666668C75.94580801944107,240.148525,104.3999240583232,241.69875104166667,118.62698207776427,239.486675C132.85404009720534,237.2745989583333,161.30815613608746,223.65191805555554,175.53521415552854,222.67253333333332C189.6076302399757,221.70379409722221,217.75246240886997,233.56351145833332,231.82487849331713,231.69417916666666C245.89729457776428,229.824846875,274.0421267466586,209.58188993055555,288.1145428311057,207.717875C302.3416008505468,205.8333763888889,330.7957168894289,214.74018229166668,345.02277490886996,216.700125C359.24983292831104,218.66006770833334,387.7039489671932,232.70899861111113,401.93100698663426,223.3974166666667C416.0034230710814,214.18704756944447,444.1482552399757,148.42262946017496,458.2206713244228,142.61232083333334C472.138445473876,136.86586175184163,499.9739937727825,170.70152835393773,513.8917679222357,177.17034583333333C528.1188259416767,183.78291481227106,556.5729419805589,190.49598645833333,570.8,194.93786666666665L570.8,261.375L61.71875,261.375Z"
                    fill-opacity="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></path>
                  <path fill="none" stroke="#a0d0e0"
                    d="M61.71875,240.36914166666668C75.94580801944107,240.148525,104.3999240583232,241.69875104166667,118.62698207776427,239.486675C132.85404009720534,237.2745989583333,161.30815613608746,223.65191805555554,175.53521415552854,222.67253333333332C189.6076302399757,221.70379409722221,217.75246240886997,233.56351145833332,231.82487849331713,231.69417916666666C245.89729457776428,229.824846875,274.0421267466586,209.58188993055555,288.1145428311057,207.717875C302.3416008505468,205.8333763888889,330.7957168894289,214.74018229166668,345.02277490886996,216.700125C359.24983292831104,218.66006770833334,387.7039489671932,232.70899861111113,401.93100698663426,223.3974166666667C416.0034230710814,214.18704756944447,444.1482552399757,148.42262946017496,458.2206713244228,142.61232083333334C472.138445473876,136.86586175184163,499.9739937727825,170.70152835393773,513.8917679222357,177.17034583333333C528.1188259416767,183.78291481227106,556.5729419805589,190.49598645833333,570.8,194.93786666666665"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                  <circle cx="61.71875" cy="240.36914166666668" r="4.48" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="118.62698207776427" cy="239.486675" r="4" fill="#a0d0e0" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="175.53521415552854" cy="222.67253333333332" r="4" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="231.82487849331713" cy="231.69417916666666" r="4" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="288.1145428311057" cy="207.717875" r="4" fill="#a0d0e0" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="345.02277490886996" cy="216.700125" r="4" fill="#a0d0e0" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="401.93100698663426" cy="223.3974166666667" r="4" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="458.2206713244228" cy="142.61232083333334" r="4" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="513.8917679222357" cy="177.17034583333333" r="4" fill="#a0d0e0" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="570.8" cy="194.93786666666665" r="4" fill="#a0d0e0" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                </svg>
                <div class="morris-hover morris-default-style" style="left: 18.6938px; top: 155px;">
                  <div class="morris-hover-row-label">2011 Q1</div>
                  <div class="morris-hover-point" style="color: #a0d0e0">
                    Item 1:
                    2,666
                  </div>
                  <div class="morris-hover-point" style="color: #3c8dbc">
                    Item 2:
                    2,666
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->

          <!-- DONUT CHART -->
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Cases</h3>

              <div class="box-tools pull-right">
                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
              </div>
            </div>
            <div class="box-body chart-responsive">
              <div class="chart" id="sales-chart" style="height: 300px; position: relative;"><svg height="300"
                  version="1.1" width="595.8" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" style="overflow: hidden; position: relative; top: -0.6px;">
                  <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.3.0</desc>
                  <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs>
                  <path fill="none" stroke="#3c8dbc"
                    d="M297.9,243.33333333333331A93.33333333333333,93.33333333333333,0,0,0,386.12775519497706,180.44625304313007"
                    stroke-width="2" opacity="0" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); opacity: 0;">
                  </path>
                  <path fill="#3c8dbc" stroke="#ffffff"
                    d="M297.9,246.33333333333331A96.33333333333333,96.33333333333333,0,0,0,388.96364732624414,181.4248826052307L425.5151459070204,194.03833029452744A135,135,0,0,1,297.9,285Z"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                  <path fill="none" stroke="#f56954"
                    d="M386.12775519497706,180.44625304313007A93.33333333333333,93.33333333333333,0,0,0,214.1848462783141,108.73398312817662"
                    stroke-width="2" opacity="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); opacity: 1;">
                  </path>
                  <path fill="#f56954" stroke="#ffffff"
                    d="M388.96364732624414,181.4248826052307A96.33333333333333,96.33333333333333,0,0,0,211.49400205154564,107.40757544301087L172.32726941747114,88.10097469226493A140,140,0,0,1,430.24163279246557,195.6693795646951Z"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                  <path fill="none" stroke="#00a65a"
                    d="M214.1848462783141,108.73398312817662A93.33333333333333,93.33333333333333,0,0,0,297.8706784690488,243.333328727518"
                    stroke-width="2" opacity="0" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); opacity: 0;">
                  </path>
                  <path fill="#00a65a" stroke="#ffffff"
                    d="M211.49400205154564,107.40757544301087A96.33333333333333,96.33333333333333,0,0,0,297.8697359912682,246.3333285794739L297.85758849987417,284.9999933380171A135,135,0,0,1,176.8120097954186,90.31165416754118Z"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="297.9"
                    y="140" text-anchor="middle" font-family="&quot;Arial&quot;" font-size="15px" stroke="none"
                    fill="#000000"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: Arial; font-size: 15px; font-weight: 800;"
                    font-weight="800" transform="matrix(1.6792,0,0,1.6792,-202.3365,-100.7994)"
                    stroke-width="0.5955171130952381">
                    <tspan dy="5.203125" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">OPD Cases</tspan>
                  </text><text x="297.9" y="160" text-anchor="middle" font-family="&quot;Arial&quot;" font-size="14px"
                    stroke="none" fill="#000000"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: Arial; font-size: 14px;"
                    transform="matrix(1.9425,0,0,1.9425,-280.8865,-143.2672)" stroke-width="0.5147879464285714">
                    <tspan dy="4.8046875" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">300</tspan>
                  </text>
                </svg></div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->

        </div>
        <!-- /.col (LEFT) -->
        <div class="col-md-6">
          <!-- LINE CHART -->
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Clinics</h3>

              <div class="box-tools pull-right">
                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
              </div>
            </div>
            <div class="box-body chart-responsive">
              <div class="chart" id="line-chart" style="height: 300px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                <svg height="300" version="1.1" width="100%" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="overflow: hidden; position: relative; left: -0.8px; top: -0.6px;">
                  <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.3.0</desc>
                  <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs><text x="49.21875" y="261.375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">0</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,261.375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="202.28125"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">50</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,202.28125H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="143.1875"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">100</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,143.1875H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="84.09375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">1000</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,84.09375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="49.21875" y="25"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2000</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M61.71875,25H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="477.39627126366946"
                    y="273.875" text-anchor="middle" font-family="sans-serif" font-size="12px" stroke="none"
                    fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2021</tspan>
                  </text><text x="251.00047843256377" y="273.875" text-anchor="middle" font-family="sans-serif"
                    font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2020</tspan>
                  </text>
                  <path fill="none" stroke="#3c8dbc"
                    d="M61.71875,229.86621250000002C75.94580801944107,229.5352875,104.3999240583232,231.8606265625,118.62698207776427,228.5425125C132.85404009720534,225.22439843749999,161.30815613608746,204.79037708333334,175.53521415552854,203.3213C189.6076302399757,201.86819114583335,217.75246240886997,219.65776718749999,231.82487849331713,216.85376875C245.89729457776428,214.04977031250002,274.0421267466586,183.68533489583336,288.1145428311057,180.88931250000002C302.3416008505468,178.06256458333334,330.7957168894289,191.42277343749998,345.02277490886996,194.3626875C359.24983292831104,197.3026015625,387.7039489671932,218.37599791666668,401.93100698663426,204.408625C416.0034230710814,190.59307135416668,444.1482552399757,91.94644419026244,458.2206713244228,83.23098125000001C472.138445473876,74.61129262776245,499.9739937727825,125.36479253090663,513.8917679222357,135.06801875000002C528.1188259416767,144.98687221840663,556.5729419805589,155.0564796875,570.8,161.7193"
                    stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                  <circle cx="61.71875" cy="229.86621250000002" r="4" fill="#3c8dbc" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="118.62698207776427" cy="228.5425125" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="175.53521415552854" cy="203.3213" r="4" fill="#3c8dbc" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="231.82487849331713" cy="216.85376875" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="288.1145428311057" cy="180.88931250000002" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="345.02277490886996" cy="194.3626875" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="401.93100698663426" cy="204.408625" r="4" fill="#3c8dbc" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="458.2206713244228" cy="83.23098125000001" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="513.8917679222357" cy="135.06801875000002" r="4" fill="#3c8dbc" stroke="#ffffff"
                    stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                  <circle cx="570.8" cy="161.7193" r="4" fill="#3c8dbc" stroke="#ffffff" stroke-width="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                </svg>
                <div class="morris-hover morris-default-style" style="left: 188.8px; top: 150px; display: none;">
                  <div class="morris-hover-row-label">2011 Q4</div>
                  <div class="morris-hover-point" style="color: #3c8dbc">
                    Item 1:
                    3,767
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->

          <!-- BAR CHART -->
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Doctors</h3>

              <div class="box-tools pull-right">
                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
              </div>
            </div>
            <div class="box-body chart-responsive">
              <div class="chart" id="bar-chart" style="height: 300px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                <svg height="300" version="1.1" width="595.8" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="overflow: hidden; position: relative; left: -0.8px; top: -0.6px;">
                  <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.3.0</desc>
                  <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs><text x="32.53125" y="261.375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">0</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M45.03125,261.375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="32.53125" y="202.28125"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">25</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M45.03125,202.28125H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="32.53125" y="143.1875"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">50</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M45.03125,143.1875H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="32.53125" y="84.09375"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">75</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M45.03125,84.09375H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="32.53125" y="25"
                    text-anchor="end" font-family="sans-serif" font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">100</tspan>
                  </text>
                  <path fill="none" stroke="#aaaaaa" d="M45.03125,25H570.8" stroke-width="0.5"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path><text x="533.2450892857142"
                    y="273.875" text-anchor="middle" font-family="sans-serif" font-size="12px" stroke="none"
                    fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2021</tspan>
                  </text><text x="383.0254464285714" y="273.875" text-anchor="middle" font-family="sans-serif"
                    font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2020</tspan>
                  </text><text x="232.80580357142858" y="273.875" text-anchor="middle" font-family="sans-serif"
                    font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2019</tspan>
                  </text><text x="82.58616071428571" y="273.875" text-anchor="middle" font-family="sans-serif"
                    font-size="12px" stroke="none" fill="#888888"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font-family: sans-serif; font-size: 12px; font-weight: normal;"
                    font-weight="normal" transform="matrix(1,0,0,1,0,6.8125)">
                    <tspan dy="3.6015625" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">2018</tspan>
                  </text>
                  <rect x="54.41997767857143" y="25" width="26.666183035714283" height="236.375" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="84.08616071428571" y="48.63749999999999" width="26.666183035714283" height="212.7375" rx="0"
                    ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="129.52979910714285" y="84.09375" width="26.666183035714283" height="177.28125" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="159.19598214285713" y="107.73124999999999" width="26.666183035714283" height="153.64375"
                    rx="0" ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="204.63962053571427" y="143.1875" width="26.666183035714283" height="118.1875" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="234.30580357142856" y="166.825" width="26.666183035714283" height="94.55000000000001" rx="0"
                    ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="279.74944196428567" y="84.09375" width="26.666183035714283" height="177.28125" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="309.415625" y="107.73124999999999" width="26.666183035714283" height="153.64375" rx="0"
                    ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="354.8592633928571" y="143.1875" width="26.666183035714283" height="118.1875" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="384.5254464285714" y="166.825" width="26.666183035714283" height="94.55000000000001" rx="0"
                    ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="429.9690848214285" y="84.09375" width="26.666183035714283" height="177.28125" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="459.6352678571428" y="107.73124999999999" width="26.666183035714283" height="153.64375"
                    rx="0" ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="505.07890624999993" y="25" width="26.666183035714283" height="236.375" rx="0" ry="0"
                    fill="#00a65a" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                  <rect x="534.7450892857142" y="48.63749999999999" width="26.666183035714283" height="212.7375" rx="0"
                    ry="0" fill="#f56954" stroke="none" fill-opacity="1"
                    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1;"></rect>
                </svg>
                <div class="morris-hover morris-default-style" style="left: 125.683px; top: 112px; display: none;">
                  <div class="morris-hover-row-label">2007</div>
                  <div class="morris-hover-point" style="color: #00a65a">
                    CPU:
                    75
                  </div>
                  <div class="morris-hover-point" style="color: #f56954">
                    DISK:
                    65
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->

        </div>
        <!-- /.col (RIGHT) -->
      </div>
      <!-- /chart-->
      <!--clinics-list-->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <div class="col-md-6">
                <div class="has-feedback">
                  <input type="text" class="form-control input-md margin"
                    placeholder="e.g. hospital name, location, department">
                  <span class="glyphicon glyphicon-search form-control-feedback"></span>
                </div>
              </div>

              <div class="col-md-6">
                <!-- <div class="btn-group pull-right margin">
                        <button type="button" class="btn btn-default">copy</button>
                        <button type="button" class="btn btn-default">csv</button>
                        <button type="button" class="btn btn-default">print</button>
                      </div> -->
              </div>
            </div>
            <!--/.box-header-->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mytable" class="table table-bordred table-striped">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Clinic Name</th>
                      <th>Patient Name</th>
                      <th>ECG Report</th>
                      <th>Clinic Doctor Name</th>
                      <th>Type of Case</th>
                      <th>Refer By</th>
                      <th>Date/Time</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>1</td>
                      <td>Gupta Clinic</td>
                      <td>Rajesh</td>
                      <td>ECG</td>
                      <td>Dr. Ashok Gupta</td>
                      <td>Type of Case</td>
                      <td>Dr. Jain</td>
                      <td>20 July, 11.00 am</td>
                      <td>
                        <button mat-flat-button color="warn" aria-label="Example icon button with a menu icon">
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Gupta Clinic</td>
                      <td>Rajesh</td>
                      <td>ECG</td>
                      <td>Dr. Ashok Gupta</td>
                      <td>Type of Case</td>
                      <td>Dr. Jain</td>
                      <td>20 July, 11.00 am</td>
                      <td>
                        <button mat-flat-button color="warn" aria-label="Example icon button with a menu icon">
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Gupta Clinic</td>
                      <td>Rajesh</td>
                      <td>ECG</td>
                      <td>Dr. Ashok Gupta</td>
                      <td>Type of Case</td>
                      <td>Dr. Jain</td>
                      <td>20 July, 11.00 am</td>
                      <td>
                        <button mat-flat-button color="warn" aria-label="Example icon button with a menu icon">
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Gupta Clinic</td>
                      <td>Rajesh</td>
                      <td>ECG</td>
                      <td>Dr. Ashok Gupta</td>
                      <td>Type of Case</td>
                      <td>Dr. Jain</td>
                      <td>20 July, 11.00 am</td>
                      <td>
                        <button mat-flat-button color="warn" aria-label="Example icon button with a menu icon">
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Gupta Clinic</td>
                      <td>Rajesh</td>
                      <td>ECG</td>
                      <td>Dr. Ashok Gupta</td>
                      <td>Type of Case</td>
                      <td>Dr. Jain</td>
                      <td>20 July, 11.00 am</td>
                      <td>
                        <button mat-flat-button color="warn" aria-label="Example icon button with a menu icon">
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="clearfix">
                  <mat-paginator [length]="50" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </div>

              </div>
            </div>
            <!--/table-->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!--//clinics-list//-->
    </div>
  </div>
</section>