<section class="login_wrapper">
  <div class="login_form div1 login-form-1">
    <!-- <div class="row">
      <div class="col-md-12 col-md-offset-2 text-center"> -->
        <!--<img class="custom-w-h" src="assets/theme/img/letswim_login_logo.png" width="" alt=" logo">-->
      <!-- </div>
    </div> -->
    <div class="row">
      <p class="login-box-msg"><strong>REGISTER</strong></p>
      <div class="col-md-10 col-md-offset-1" *ngIf="error">
          <p class="login-box-msg alert alert-danger"><strong>{{ error }}</strong></p>
      </div>  
  </div>
    <div class="row">
        <form style="padding: 12px;" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="col-md-12">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstName">First Name <span class="m-t-5"> *</span></label>
            <input type="text" formControlName="firstName" id="firstName" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastName">First Name <span class="m-t-5"> *</span></label>
            <input type="text" formControlName="lastName" id="lastName" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">First Name is required</div>
            </div>
          </div>
        </div> 
      </div>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="form-group">
            <label for="emailId">Email Id<span class="m-t-5"> *</span></label>
            <input type="text" formControlName="emailId" id="emailId" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" />
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
              <div *ngIf="f.emailId.errors.required">Email is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="form-group">
            <label for="password">Password<span class="m-t-5"> *</span></label>
            <input type="text" formControlName="password" id="password" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="form-group">
            <label for="confirmPassword">Confirm Password<span class="m-t-5"> *</span></label>
            <input type="text" formControlName="confirmPassword" id="confirmPassword" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm password</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-xs-8 col-md-offset-2 col-xs-offset-2 text-center">
          <button type="submit" class="btn btn-info btn-block btn-flat" style="margin-top: 20px;">
              Register
              <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
          </button>
          You have an account?<a routerLink="/login"> Login</a>
      </div>
      </div>
      </form>
      </div>
    



    <!-- <div class="row">
      <div class="col-md-12">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName">First Name <span class="m-t-5"> *</span></label>
                <input type="text" formControlName="firstName" id="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="email">Email<span class="m-t-5"> *</span></label>
                <input type="text" formControlName="email" id="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>

              <div class="form-group">
                <label for="instituteName">Institute Name<span class="m-t-5"> *</span></label>
                <input type="text" formControlName="instituteName" id="instituteName" class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && f.instituteName.errors }" />
                <div *ngIf="submitted && f.instituteName.errors" class="invalid-feedback">
                  <div *ngIf="f.instituteName.errors.required">Institute name is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="instituteAddress">Institute Address<span class="m-t-5"> *</span></label>
                <textarea formControlName="instituteAddress" id="instituteAddress" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.instituteAddress.errors }"></textarea>
                <div *ngIf="submitted && f.instituteAddress.errors" class="invalid-feedback">
                  <div *ngIf="f.instituteAddress.errors.required">Institute address is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="instituteInfo">About Institute</label>
                <textarea formControlName="instituteInfo" id="instituteInfo" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.instituteInfo.errors }"></textarea>
                <div *ngIf="submitted && f.instituteInfo.errors" class="invalid-feedback">
                  <div *ngIf="f.instituteInfo.errors.required">About institute address is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastName">Last Name <span class="m-t-5"> *</span></label>
                <input type="text" formControlName="lastName" id="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="mobile">Mobile <span class="m-t-5"> *</span></label>
                <input type="text" formControlName="mobile" id="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                  <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                  <div *ngIf="f.mobile.errors.pattern">Please, Enter 10 digit Mobile Number</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password <span class="m-t-5"> *</span></label>
                <input type="password" formControlName="password" id="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                  <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
              </div>
              <div class="form-group">
                <label for="confirmPassword">Confirm Password <span class="m-t-5"> *</span></label>
                <input type="password" formControlName="confirmPassword" id="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <br>
            <br>
            <br>
          </div>
          <div class="row">
            <br>
            <div class="text-center">
              <button class="btn btn-primary mr-1">Register</button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button class="btn btn-warning" type="reset" (click)="onReset()">Cancel</button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              You have an account?<a routerLink="/login"> Login</a>
            </div>
            <br>
          </div>
        </form>
      </div>
    </div> -->
    <div class="col-md-12 col-xs-12 panel-footer padding-md no-margin no-border bg-danger text-center text-white login-footer"
    style="width: 100%;">
    © 2021 Shri Sanatan
</div>
  </div>
</section>
