<div class="wrapper">
  <ngx-loading-bar [height]="'4px'"></ngx-loading-bar>
  <app-header></app-header>
  <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  <app-footer></app-footer>
</div>
