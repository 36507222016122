<section class="content">
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
                <p style="color: white">Please Wait. </p>  
                </ngx-spinner>
    <div class="row">
        <div class="col-md-12">
            <div class="box box-danger">
                <div class="box-header">
                </div>
            
                <!--/.box-header-->
                <div class="box-body">
                    <form #addDoctorform="ngForm" (ngSubmit)="addDoctorform.form.valid && onSubmit(addDoctorform)" novalidate>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="book_title">Doctor Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="addDoctor.doctor_name" name="doctorName" #doctorName="ngModel"  placeholder="doctor name" required> 
                                    <div class="invalid-feedback" *ngIf="addDoctorform.submitted && doctorName.invalid" class="invalid">
                                        <p *ngIf="doctorName.errors?.required">Doctor name is required.</p>
                                      </div> 
                                    
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="author_name">Mobile No.<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" placeholder="mobile no." [(ngModel)]="addDoctor.mobile" name="mobilenumber" minlength="10" maxlength="10" #mobilenumber="ngModel" required> 
                                    <div class="invalid-feedback" *ngIf="addDoctorform.submitted && mobilenumber.invalid" class="invalid">
                                        <p *ngIf="mobilenumber.errors?.required">Mobile number is required.</p>
                                      </div> 
                                    
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Upload Profile Pic <span class="text-red"> (.jpg*, .png*)</span></label>
                                    <div class="has-feedback">
                                        <input type="file" class="form-control input-md" accept="image/gif, image/jpeg, image/png"  (change)="attachDocumentFile($event.target.files)" name="profilepic">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Email ID<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="addDoctor.email" name="emailid" #emailid="ngModel" required
                                        placeholder="email id">
                                        <div class="invalid-feedback" *ngIf="addDoctorform.submitted && emailid.invalid" class="invalid">
                                            <p *ngIf="emailid.errors?.required">Email is required.</p>
                                          </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="publisher_name">Hospital Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="addDoctor.hospital_name" name="hospitalName" #hospitalName="ngModel" required
                                        placeholder="hospital Name">
                                        <div class="invalid-feedback" *ngIf="addDoctorform.submitted && hospitalName.invalid" class="invalid">
                                            <p *ngIf="hospitalName.errors?.required">Hospital name is required.</p>
                                          </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label>Department Name</label>
                                    <select class="form-control" name="department_name" #department_name="ngModel" [(ngModel)]="addDoctor.department_id" (change)="getSelectedDepartment($event.target.value)" required>
                                        
                                        <option *ngFor="let list of departmentList" [value]="list.id">{{list.name}}</option>        
                                    </select>
                                  </div>
                            </div> -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>State</label>
                                    <select class="form-control" name="state" [(ngModel)]="addDoctor.state_id" #state="ngModel" (change)="getSelectedState()">
                                        <option *ngFor="let list of stateList" [value]="list.id">{{list.state_name}}</option>
                                    </select>
                                  </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>District</label>
                                    <select class="form-control" 
                                    [disabled]="!addDoctor.state_id"
                                      name="district" [(ngModel)]="addDoctor.district_id" #district="ngModel">
                                       
                                    <option [value]="list.id" *ngFor="let list of districtList">{{list.district_name}}</option>

                                    </select>
                                  </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Address</label>
                                    <textarea type="text-area" row="4" class="form-control" name="address" [(ngModel)]="addDoctor.address" #address="ngModel"
                                        placeholder="address"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6">
                                    <div class="form-group">
                                            <label for="hodMobile">Password</label>
                                            <input type="password" class="form-control" id="" placeholder="Password" name="pwd" [(ngModel)]="addDoctor.password" #pwd="ngModel" required>
                                           
                                            <div *ngIf="addDoctorform.submitted && pwd.invalid" class="invalid">
                                                <p *ngIf="pwd.errors?.required">Password is required.</p>
                                            </div>
                                        </div>
                            </div>
                        </div>
                        <button type="submit" class="pull-right" style="margin-top:23px;" mat-raised-button
                                    color="primary">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- /.row -->
</section>
