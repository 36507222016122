<header class="main-header">
  <!-- Logo -->
  <a routerLink="/admin/home" class="logo" style="height: 56px;">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <!-- <span class="logo-mini"><b>A</b>LT</span> -->
<!--    <span class="logo-mini">Study</span>-->
    <span class="logo-mini">
      <i class="fa fa-stethoscope text-light"></i>
      <!-- <img src="assets/images/logo.png" alt=""> -->
    </span>
    <!-- logo for regular state and mobile devices -->
    <!-- <span class="logo-lg"><b>Admin</b>LTE</span> -->
    <span class="logo-lg text-light"><b class="fa fa-stethoscope"> Stemi </b>Act</span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top" style="box-shadow: 0 1px 6px 0 rgba(32,33,36,.28);">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </a>

    <span class="header-page-title font-bold" data-toggle="" role=""
      style="font-size: 16px; font-weight: normal;">
        <!-- Dashboard -->
        {{ breadcrumbs[0]['label'] | titlecase }}
        <!-- <div class="btn-toolbar" role="toolbar">
          <div class="btn-group" role="group">
            <button class="btn btn-default">Home</button>
          </div>
          <div class="btn-group" role="group">
            <button class="btn btn-default">Inbox</button>
            
           <span class="label label-success">4</span>
          </div>
        </div>-->
    </span> 

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav"> 
       <li class="dropdown messages-menu">
         <a class="dropdown-toggle" data-toggle="dropdown">
           <i class="fa fa-bell"></i>
           <span class="label label-success">4</span>
         </a>
         <ul class="dropdown-menu">
           <li class="header">You have 4 messages</li>

           <li class="footer"><a href="#">See All Messages</a></li>
         </ul>
       </li>
        <!-- Notifications: style can be found in dropdown.less
      <li class="dropdown notifications-menu">-->
<!--          <a href="#" class="dropdown-toggle" data-toggle="dropdown">-->
<!--            <i class="fa fa-bell-o"></i>-->
<!--            <span class="label label-warning">10</span>-->
<!--          </a>-->
<!--          <ul class="dropdown-menu">-->
<!--            <li class="header">You have 10 notifications</li>-->
<!--            <li>-->
              <!-- inner menu: contains the actual data -->
<!--              <ul class="menu">-->
<!--                <li>-->
<!--                  <a href="#">-->
<!--                    <i class="fa fa-users text-aqua"></i> 5 new members joined today-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li class="footer"><a href="#">View all</a></li>-->
<!--          </ul>-->
<!--        </li>-->
        <!-- Tasks: style can be found in dropdown.less -->
        <li class="dropdown tasks-menu">
<!--          <a href="#" class="dropdown-toggle" data-toggle="dropdown">-->
<!--            <i class="fa fa-flag-o"></i>-->
<!--            <span class="label label-danger">9</span>-->
<!--          </a>-->
<!--          <ul class="dropdown-menu">-->
<!--            <li class="header">You have 9 tasks</li>-->
<!--            <li>-->
              <!-- inner menu: contains the actual data -->
<!--              <ul class="menu">-->
<!--                <li>&lt;!&ndash; Task item &ndash;&gt;-->
<!--                  <a href="#">-->
<!--                    <h3>-->
<!--                      Design some buttons-->
<!--                      <small class="pull-right">20%</small>-->
<!--                    </h3>-->
<!--                    <div class="progress xs">-->
<!--                      <div class="progress-bar progress-bar-aqua" style="width: 20%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">-->
<!--                        <span class="sr-only">20% Complete</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </li>-->
                <!-- end task item -->
<!--              </ul>-->
<!--            </li>-->
<!--            <li class="footer">-->
<!--              <a href="#">View all tasks</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
           <img src="assets/dist/img/user2-160x160.jpg" class="user-image" alt="User Image">
            <!-- <i class="fa fa-user-image" style="font-size: 24px;"></i> -->
            <span class="hidden-xs">{{ loggedInUser }}</span>
          </a>
          <ul class="dropdown-menu">
            <!-- User image -->
<!--            <li class="user-header">-->
<!--              <img src="assets/dist/img/user2-160x160.jpg" class="img-circle" alt="User Image">-->

<!--              <p>-->
<!--                Alexander Pierce - Web Developer-->
<!--                <small>Member since Nov. 2012</small>-->
<!--              </p>-->
<!--            </li>-->
            <!-- Menu Body -->
<!--            <li class="user-body">-->
<!--              <div class="row">-->
<!--                <div class="col-xs-4 text-center">-->
<!--                  <a href="#">Followers</a>-->
<!--                </div>-->
<!--                <div class="col-xs-4 text-center">-->
<!--                  <a href="#">Sales</a>-->
<!--                </div>-->
<!--                <div class="col-xs-4 text-center">-->
<!--                  <a href="#">Friends</a>-->
<!--                </div>-->
<!--              </div>-->
              <!-- /.row -->
<!--            </li>-->
            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-left">
                <a class="btn btn-default btn-flat">Profile</a>
              </div>
              <div class="pull-right">
                <!-- <a href="#" class="btn btn-default btn-flat">Sign out</a> -->
                <button (click)="onLogout()" class="btn btn-default btn-flat">Logout</button>
              </div>
            </li>
          </ul>
        </li>
        <!-- Control Sidebar Toggle Button -->
        <!-- <li>
          <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
        </li> -->
      </ul>
    </div>
  </nav>
</header>
