<section class="content">
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
                <p style="color: white">Please Wait. </p>  
                </ngx-spinner>
 <div class="row">
 <div class="col-md-12">
 <div class="box box-danger">

 <!--/.box-header-->
 <div class="box-body">
 <mat-tab-group>
 <mat-tab label="Patient Summary">
 <section class="content" style="margin-top:23px;">
 <div class="row">
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Patient Details</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive">
 <table class="table table-bordered">
 <tbody>
 <tr>
 </tr>
 <tr>
 <th>Incident Date :</th>
 <td>{{patientDetails.incident_time}}</td>
 <th> Patient Name :</th>
 <td>{{patientDetails.name}}</td>
 </tr>
 <tr>
 <th>Patient Age :</th>
 <td>{{patientDetails.age}}</td>
 <th> Gender :</th>
 <td> {{patientDetails.gender}}</td>
 </tr>
 <tr>
 <th>Patient Contact No. :</th>
 <td>{{patientDetails.mobile}}</td>
 <th>Attendee Contact No. :</th>
 <td>{{patientDetails.attendee_contact}}</td>
 </tr>
 <tr>
 <th>Patient Address: </th>
 <td>{{patientDetails.patient_address}}</td>
 <th>Hospital :</th>
 <td>{{patientDetails.attending_clinic_name}}</td>
 </tr>
 <tr>
 <th>Attending Doctor: </th>
 <td>{{patientDetails.doctor_name}}</td>
 <th>Attendee Signature :</th>
 <td>
     <img [src]="attendeeSignature" style="height: 3rem;
     width: 8rem;" alt="signature"></td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>


 </div>
 <!-- /.col -->
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Past Patient Medical History</h3>
 </div>
 <!-- /.box-header -->
 <!-- box-body -->
 <div class="box-body table-responsive">
 <table class="table table-bordered">
 <tbody>
 <tr>
 <th>Chest Pain:</th>
 <td>{{patientMedicalHostory.chest_pain ==='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Dyspnea Class:</th>
 <td>{{patientMedicalHostory.dyspnea_class}}</td>
 </tr>
 <tr>
 <th>Syncope:</th>
 <td>{{patientMedicalHostory.syncope ==='1' ? 'Yes' : 'No' }}</td>
 </tr>
 <tr>
 <th>Onset Symptoms Time :</th>
 <td>{{patientMedicalHostory.onset_symptoms_time}}</td>
 </tr>
 <tr>
 <th>Past Cardiac History :</th>
 <td>{{patientMedicalHostory.past_cardiac_case_history_name}}</td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 <!--/row/-->
 
 <!--Initial Medical History-->
 <div class="row">
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Initial clinical Medical History</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive">
 <table class="table table-bordered">
 <tbody>
 <tr>
 <th>Stable:</th>
 <td>{{initialMedicalHistory.stable == '1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>HF:</th>
 <td>{{initialMedicalHistory.hf == '1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Shock:</th>
 <td>{{initialMedicalHistory.shock == '1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Hypotension :</th>
 <td>{{initialMedicalHistory.hypotension}}</td>
 </tr>
 <tr>
 <th>Pul. Edema :</th>
 <td>{{initialMedicalHistory.pulmonary_edem}}</td>
 </tr>
 <tr>
 <th>Pulse :</th>
 <td>{{initialMedicalHistory.pulse}} </td>
 </tr>
 <tr>
 <th>BP :</th>
 <td>{{initialMedicalHistory.bp}} mmHg</td>
 </tr>
 <tr>
 <th>SPO2 :</th>
 <td>{{initialMedicalHistory.spo2}}%</td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 <!--/remark---->
 <div class="col-md-6">
 <!--pupil-->
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Patient Diagnosis Medication Details</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive" *ngFor="let list of medicationDtails">
 <table class="table table-bordered">
 <tbody>
 <tr>
 <th>Drugs Name:</th>
 <td>{{list.medication}}</td>
 </tr>
 <tr>
 <th>Route:</th>
 <td>{{list.route}}</td>
 </tr>
 <tr>
 <th>Drug Time:</th>
 <td>{{list.grugs_given_time}}</td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 
 
 <!--Time-->
 <div class="row">
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Diagnosis & Outcome</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive" *ngIf="!diagnosisAndOutcome">
     <h3>Data not available!</h3>
 </div>
 <div class="box-body table-responsive" *ngIf="diagnosisAndOutcome">
 <table class="table table-bordered">
 <tbody>
 <tr>
 <th>Diagnosis:</th>
 <td>{{diagnosisAndOutcome.diagnosis}}</td>
 </tr>
 <tr>
 <th>Other Diagnosis:</th>
 <td>{{diagnosisAndOutcome.other_diagnosis=='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Thrombolysis Done:</th>
 <td>{{diagnosisAndOutcome.is_thrombolysis_done}}</td>
 </tr>
 <tr>
 <th>Reason For not done :</th>
 <td>{{diagnosisAndOutcome.reason_for_not_tnk}}</td>
 </tr>
 <tr>
 <th>Success :</th>
 <td>{{diagnosisAndOutcome.successful=='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Unsuccess :</th>
 <td>{{diagnosisAndOutcome.unsuccessful =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Chest pain resolution :</th>
 <td>{{diagnosisAndOutcome.chest_pain_resolution}}</td>
 </tr>
 <tr>
 <th>If partial :</th>
 <td>{{diagnosisAndOutcome.if_partial}}</td>
 </tr>
 <tr>
 <th>Cardiac Arrest :</th>
 <td>{{diagnosisAndOutcome.cardiac_arrest =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Cardiac Arrest Time :</th>
 <td>{{diagnosisAndOutcome.cardiac_arrest_time}}</td>
 </tr>
 <tr>
 <th>VF :</th>
 <td>{{diagnosisAndOutcome.vf =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>CHB:</th>
 <td>{{diagnosisAndOutcome.chb =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>VPC :</th>
 <td>{{diagnosisAndOutcome.vpc =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Other Blocks :</th>
 <td>{{diagnosisAndOutcome.others_blocks}}</td>
 </tr>
 <tr>
 <th>CPR Performed :</th>
 <td>{{diagnosisAndOutcome.cpr_performed =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>CPR Started Time :</th>
 <td>{{diagnosisAndOutcome.cpr_started_time}}</td>
 </tr>
 <tr>
 <th>Shock Advised :</th>
 <td>{{diagnosisAndOutcome.shock_advised =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Frequency :</th>
 <td>{{diagnosisAndOutcome.frequency}}</td>
 </tr>
 <tr>
 <th>No. of Shocks :</th>
 <td>{{diagnosisAndOutcome.no_ofshocks}}</td>
 </tr>
 <tr>
 <th>Discharged :</th>
 <td>{{diagnosisAndOutcome.discharged =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Death :</th>
 <td>{{diagnosisAndOutcome.death =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Refferels to Higher Center :</th>
 <td>{{diagnosisAndOutcome.referred =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Transferred Time :</th>
 <td>{{diagnosisAndOutcome.transfer_time}}</td>
 </tr>
 <tr>
 <th>Hub :</th>
 <td>{{diagnosisAndOutcome.hub =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Other Hospital :</th>
 <td>{{diagnosisAndOutcome.other_hospital =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Ed :</th>
 <td>{{diagnosisAndOutcome.ed =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Cathlab :</th>
 <td>{{diagnosisAndOutcome.cathlab =='1' ? 'Yes' : 'No'}}</td>
 </tr>
 <tr>
 <th>Date & Time :</th>
 <td>{{diagnosisAndOutcome.transfer_time}}</td>
 </tr>
 <tr>
 <th>Referring Doctor Note :</th>
 <td>{{diagnosisAndOutcome.ref_doc_note}}</td>
 </tr>
 <tr>
 <th>Reason :</th>
 <td>{{diagnosisAndOutcome.reason}}</td>
 </tr>
 <tr>
 <th>Reason If Not :</th>
 <td>{{diagnosisAndOutcome.reason_if_not}}</td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 <!--/remark---->
 <div class="col-md-6">
 <!--Thrombolysis-->
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Medication For TNK</h3>
 </div>
 <!-- /.box-header -->
 <ng-container *ngIf="medicationForTNK.length>0">
        <div class="box-body table-responsive" *ngFor="let list of medicationForTNK">
                <table class="table table-bordered">
                <tbody>
                <tr>
                <th>Drugs Name:</th>
                <td>{{list.medication}}</td>
                </tr>
                <tr>
                <th>Route:</th>
                <td>{{list.route}}</td>
                </tr>
                <tr>
                <th>Drug Time:</th>
                <td>{{list.grugs_given_time}}</td>
                </tr>
                </tbody>
                </table>
                </div>
 </ng-container>

 <!-- /.box-body -->
 </div>
 <div class="box">
 <!-- <div class="box-header with-border">
 <h3 class="box-title">Consent for Thrombolysis</h3>
 </div> -->
 <!-- /.box-header -->
 
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 
 </section>
 </mat-tab>
 <!--patient summary-->
 <mat-tab label="ECG Report">
 <section class="content" style="margin-top:23px;">
 <div class="row">
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">ECG Before TNK</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body">
 <div class="direct-chat-info clearfix">
 <span class="direct-chat-name pull-left">Date & Time</span>
 <span class="direct-chat-timestamp pull-right">{{ecgReportbefore.ecg_date}}</span>
 </div>
 
 <img class="img-responsive" [src]="ecgReportbefore.egc_url" alt="ecg-report">
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">ECG After TNK</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body" *ngFor="let list of ecgRepostAfter">
 <div class="direct-chat-info clearfix">
 <span class="direct-chat-name pull-left">Date & Time</span>
 <span class="direct-chat-timestamp pull-right">{{list.ecg_date}}</span>
 </div>
 <img class="img-responsive" [src]="list.egc_url" alt="ecg-report">
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 </section> 
 </mat-tab>

 <mat-tab label="Doctor Prescription"> 
 <section class="content" style="margin-top:23px;">
 <!--Doctor Prescription"-->
 <div class="row">
 <div class="col-md-6">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Doctor Entry</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive">
 <table class="table table-bordered">
 <tbody>
 <tr>
 <th>Is it STEMI? :</th>
 <td>{{doctorPrecription.is_stemi === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.is_stemi" value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.is_stemi" value="0"></span> No</td> -->
 </tr>
 <tr>
 <th>ASMI :</th>
 <td>{{doctorPrecription.asmi === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.asmi" value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.asmi" value="0"></span> No</td> -->
 </tr>
 <tr>
 <th>ALMI:</th>
 <td>{{doctorPrecription.almi === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" name="yes" disabled [(ngModel)]="doctorPrecription.almi" value="1"></span> Yes</td>
 <td><span><input type="radio" name="no" disabled [(ngModel)]="doctorPrecription.almi" value="0"></span> No</td> -->
 </tr>
 <tr>
 <th>Anterior Wall :</th>
 <td>{{doctorPrecription.anterior_wall === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" disabled [checked]="doctorPrecription.anterior_wall" value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [checked]="doctorPrecription.anterior_wall" value="0"></span> No</td> -->
 </tr>
 <tr>
 <th>IWMI :</th>
 <td>{{doctorPrecription.iwmi === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.iwmi" value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.iwmi" value="0"></span> No</td> -->
 </tr>
 <tr>
 <th>Thrombolysis Indicated :</th>
 <td>{{doctorPrecription.thrombolysis_indicated === '1' ? 'Yes' : 'No'}}</td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.thrombolysis_indicated" value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.thrombolysis_indicated" value="1"></span> No</td> -->
 </tr>
 <!-- <tr>
 <th>Other Findings in ECG :</th>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription." value="1"></span> Yes</td>
 <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.iwmi" value="1"></span> No</td>
 </tr> -->
 <tr>
 <th>Tachycardia :</th>
 <td><input type="text" disabled [(ngModel)]="doctorPrecription.tachycardia" name="tach"></td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.tachycardia" value="1"></span> No</td> -->
 </tr>
 <tr>
 <th>Bradyarrhythmia :</th>
 <td><input type="text" disabled [(ngModel)]="doctorPrecription.bradyarrhythmia" name="brady"></td>
 <!-- <td><span><input type="radio" disabled [(ngModel)]="doctorPrecription.bradyarrhythmia" value="1"></span> No</td> -->
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 <!--/Doctor Prescription"---->
 <div class="col-md-6">
 <!--drug name-->
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Drugs Name</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body table-responsive" *ngIf="medicinePrecribed.length == 0">
     <h3>Data not available!</h3>
 </div>
 <div class="box-body table-responsive" *ngIf="medicinePrecribed.length>0">
 <table class="table table-bordered">
 <tbody>
 <tr *ngFor="let list of medicinePrecribed; let index=index">
 <td>{{index+1}}.</td>
 <td>{{list.name}}</td>
 </tr>
 </tbody>
 </table>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 <!--Statement of patient--------------------------->
 <div class="row">
 <div class="col-md-12">
 <div class="box">
 <div class="box-header with-border">
 <h3 class="box-title">Doctor Signature</h3>
 </div>
 <!-- /.box-header -->
 <div class="box-body">
 <div class="timeline-item">
 <!-- <span class="time pull-right text-muted">
 <i class="fa fa-clock-o "></i>
 12-08-21 | 12:05</span> -->
 <h3 class="timeline-header">Doctor Name: <span class="timeline-header"> {{patientDetails.doctor_name}}</span></h3>
 <!-- <div class="timeline-body">
 I hereby consent and authorised phisicianand medical personnel to administer
 and perform medical examination, investigations, medical treatments, pre-hospital procedures,
 use of information for research purpose duringthe course of patient care be the deemed 
 advisory of necessary.
 </div> -->
 <div class="timeline-footer" style="padding-top:10px">
 <h3 class="timeline-header">Signature : 
 <span class="text-md"> 
     <img [src]="doctorPrecription.doctor_signature_url" alt="sign" style="height:60px">
     <!-- <input type="text" style="height:60px"> -->
    </span></h3>
 </div>
 </div>
 </div>
 <!-- /.box-body -->
 </div>
 </div>
 </div>
 </section>
 </mat-tab>
 </mat-tab-group>
 </div>
 </div>
 </div>
 </div>
 <!-- /.row -->
</section>