<!-- Left side column. contains the sidebar -->
<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <ul class="sidebar-menu" data-widget="tree">
      <!-- <li class="header">MAIN NAVIGATION</li> -->
      <li class="treeview" style="height:auto" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/admin/home">
          <i class="fa fa-dashboard"></i><span>Dashboard</span>
        </a>
      </li>

      <li class="treeview" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a href="#">
          <i class="fa fa-hospital-o"></i>
          <span>Spoke Management</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/create-sub-hub"><i class="fa fa-circle-o"></i>Create Spoke</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/view-sub-hub"><i class="fa fa-circle-o"></i>Update Spoke Details</a>
          </li>
        </ul>
      </li>
      <li class="treeview" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a href="#">
          <i class="fa fa-hospital-o"></i>
          <span>Hub Doctor Management</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/add-doctor"><i class="fa fa-circle-o"></i>Add Hub Doctor</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/view-doctor"><i class="fa fa-circle-o"></i>Update Doctor Details</a>
          </li>
        </ul>
      </li>
      <li class="treeview" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a href="#">
          <i class="fa fa-hospital-o"></i>
          <span>Patient Management</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/all-patient-list"><i class="fa fa-circle-o"></i>List of all Patients</a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a (click)="downloadPatientData()"><i class="fa fa-circle-o"></i>Download Patient Data</a>
          </li>
        </ul>
      </li>
      
      <!-- <li class="treeview" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a href="#">
          <i class="fa fa-wheelchair"></i>
          <span>Patient Management</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/admin/patient-list"><i class="fa fa-circle-o"></i>Patient List</a>
          </li>
        </ul>
      </li> -->
      
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>

<!-- =============================================== -->