<section class="content-header">
  <h1 style="height: 22px;"></h1>
  <!--    <h1>-->
  <!--      Fixed Layout-->
  <!--      <small>Blank example to the fixed layout</small>-->
  <!--    </h1>-->
      <!-- <ol class="breadcrumb"> -->
        <!-- <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
        <li><a href="#">Layout</a></li>
        <li class="active">Fixed</li> -->
      <ol class="breadcrumb float-sm-right" *ngFor="let breadcrumb of breadcrumbs">
              <li class="breadcrumb-item active"><a href="admin/home">Home</a></li>
              <li class="breadcrumb-item">
                  {{ breadcrumb.label }}
              </li>
            </ol>
  </section>
