<section class="content">
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
                <p style="color: white">Please Wait. </p>  
                </ngx-spinner>
    <div class="row">
        <div class="col-md-12">
            <div class="box box-danger">
                <div class="box-header">
                    <!-- <button class="pull-right" data-toggle="modal" data-target="#myModal" mat-raised-button
                        color="primary">Add Class Stream</button> -->
                </div>
                <!--/.box-header-->
                <div class="box-body">
                <form #addClinicform="ngForm" (ngSubmit)="addClinicform.form.valid && onSubmit(addClinicform)" novalidate>
                    <div class="row">
                       
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="hospitalName">Center name</label>
                                    <input type="email" class="form-control" id="" placeholder="e.g. AIIMs Delhi" [(ngModel)]="addClinic.hospital_name" name="hospitalName" #hospitalName="ngModel" required required>
                                    <div class="invalid-feedback" *ngIf="addClinicform.submitted && hospitalName.invalid" class="invalid">
                                        <p *ngIf="hospitalName.errors?.required">Hospital name is required.</p>
                                      </div> 
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" placeholder="abc@gmail.com" name="email" [(ngModel)]="addClinic.email" #email="ngModel" required>
                                    <div class="invalid-feedback" *ngIf="addClinicform.submitted && email.invalid" class="invalid">
                                        <p *ngIf="email.errors?.required">Email is required.</p>
                                      </div> 
                                  </div>
                            </div>
                        
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Mobile No.</label>
                                <input type="mobile" class="form-control" minlength="10" maxlength="10" id="mobile" placeholder="mobile no." name="mobile" [(ngModel)]="addClinic.mobile" #mobile="ngModel" required>
                                <div class="invalid-feedback" *ngIf="addClinicform.submitted && mobile.invalid" class="invalid">
                                    <p *ngIf="mobile.errors?.required">Mobile is required.</p>
                                    <p *ngIf="!mobile.errors?.required && mobile.errors?.minlength">Mobile number should be 10.</p>
                                    <p *ngIf="!mobile.errors?.required && !mobile.errors?.minlength && mobile.errors?.maxlength">Mobile number should be 10.</p>

                                  </div> 
                              </div>
                        </div>
                 
                    </div>
                    <div class="row">
                       
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>State</label>
                                    <select class="form-control" name="state" [(ngModel)]="addClinic.state_id" #state="ngModel" (change)="getSelectedState()" required>
                                        <option *ngFor="let list of stateList" [value]="list.id">{{list.state_name}}</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="addClinicform.submitted && state.invalid" class="invalid">
                                        <p *ngIf="state.errors?.required">State is required.</p>
                                      </div> 
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>District</label>
                                    <select class="form-control" name="district" 
                                    [disabled]="!addClinic.state_id"
                                     [(ngModel)]="addClinic.district_id" #district="ngModel" required>
                                       
                                        <option [value]="list.id" *ngFor="let list of districtList">{{list.district_name}}</option>

                                    </select>
                                    <div class="invalid-feedback" *ngIf="addClinicform.submitted && district.invalid" class="invalid">
                                        <p *ngIf="district.errors?.required">District is required.</p>
                                      </div> 
                                  </div>
                            </div>
                        
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Address</label>
                                <input type="text" class="form-control" id="address" placeholder="e.g. South Delhi" name="address" [(ngModel)]="addClinic.address" #address="ngModel" required>
                                <div class="invalid-feedback" *ngIf="addClinicform.submitted && address.invalid" class="invalid">
                                    <p *ngIf="address.errors?.required">Address is required.</p>
                                  </div> 
                              </div>
                        </div>
                   
                    </div>
                    <div class="row">
                       
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label>Department Name</label>
                                    <select class="form-control" name="department_name" #department_name="ngModel" [(ngModel)]="addClinic.department_id" (change)="getSelectedDepartment($event.target.value)">
                                        
                                        <option *ngFor="let list of departmentList" [value]="list.id">{{list.name}}</option>        
                                    </select>
                                  </div>
                            </div> -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="hodName">Name of Spoke Head</label>
                                    <input type="text" class="form-control" id="" placeholder="name of head of department" name="hodName" [(ngModel)]="addClinic.doctor_name" #hodName="ngModel">
                                </div>
                            </div>
                        
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="hodMobile">Contact of Spoke Head</label>
                                <input type="text" class="form-control" id="" minlength="10" maxlength="10" placeholder="contact of head of department" name="hodContact" [(ngModel)]="addClinic.hod_contact" #hodContact="ngModel" required>
                                <div *ngIf="addClinicform.submitted && hodContact.invalid" class="invalid">
                                    <p *ngIf="hodContact.errors?.required">Password is required.</p>
                                    <p *ngIf="!hodContact.errors?.required && hodContact.errors?.minlength">Mobile number should be 10.</p>
                                    <p *ngIf="!hodContact.errors?.required && !hodContact.errors?.minlength && hodContact.errors?.maxlength">Mobile number should be 10.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                                <div class="form-group">
                                        <label for="hodMobile">Password</label>
                                        <input type="password" class="form-control" id="" placeholder="Password" name="password" [(ngModel)]="addClinic.password" #password="ngModel" required>
                                       
                                        <div *ngIf="addClinicform.submitted && password.invalid" class="invalid">
                                            <p *ngIf="password.errors?.required">Password is required.</p>
                                        </div>
                                    </div>
                        </div>
                   
                    </div>
                    <button mat-raised-button color="primary" class="pull-right" 
                            style="margin-top:23px; margin-bottom: 10px">Save</button>
                </form>
                </div>
            </div>
        </div>
    </div>
    <!-- /.row -->
</section>

